<template>
  <div>

    <app-step step="3" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-credit-card"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Tarjeta</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">
      
      <div class="row d-flex justify-content-between px-3">
        <div class="col-sm-3 border border-right-0 px-0">
          <h5 class="pt-3 pl-3 text-primary mb-0">Resumen</h5>
          
          <div v-for="(item, key) in order.list" :key="key" 
            class="pt-2 px-3 text-muted">
            <hr class="mt-2">
            <p class="mb-0"><strong>({{ item.model }}) {{ item.description }}</strong></p>
            <div class="row">
              <div class="col-5">
                Cantidad: {{ item.quantity }}
              </div>
              <div class="col-7 text-right">
                Subtotal: $ {{ item.subtotal }}
              </div>
            </div>
          </div>

          <div class="px-3"><hr></div>
          <div class="row pt-2 px-3">
            <div class="col-8"><h6 class="text-primary">Subtotal</h6></div>
            <div class="col-4 text-right"><h6 class="text-muted">$ {{ subtotal }}</h6></div>
          </div>
          <div class="row py-1 px-3">
            <div class="col-8"><h6 class="text-primary">{{ shipping.method }}</h6></div>
            <div class="col-4 text-right"><h6 class="text-muted">$ {{ shipping.total }}</h6></div>
          </div>
          <div class="row pb-2 px-3">
            <div class="col-8"><h6 class="text-primary">Total</h6></div>
            <div class="col-4 text-right"><h6 class="text-muted">$ {{ total }}</h6></div>
          </div>

          <div class="py-2 px-3">
            <b-button v-on:click="add_order()" variant="success" block :class="button_class">Realizar pago</b-button>
          </div>

        </div>
        <div class="col-sm-9 border border-left-0 px-0">
          <p class="small text-right text-muted pt-3 pr-3">
            Este pago está siendo procesado en una frame de BBVA.
            <strong class="text-success h6 ml-2"><i class="fad fa-shield-check"></i></strong>
          </p>
          <iframe 
            :src="url" 
            class="border">
          </iframe>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import settings from '../settings'

export default {
  name: 'Order',
  data() {
    return {
      xwt: '',
      customer: '',
      order: '',
      order_id: '',
      subtotal: '',
      shipping: '',
      payment: '',
      total: '',

      button_class: '',

      url: ''
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  methods: {
    async add_order() {
      this.button_class = 'd-none'

      var headers = { xwt: this.xwt }

      var data = {
        order: {
          order_id: '',
          customer_id: this.customer.customer_id,
          customer_group_id: this.customer.customer_group_id,
          firstname: this.customer.firstname,
          lastname: this.customer.lastname,
          email: this.customer.email,
          telephone: this.customer.telephone,

          payment_firstname: this.shipping.data.firstname,
          payment_lastname: this.shipping.data.lastname,
          payment_company: this.shipping.data.firstname,
          payment_address_1: this.shipping.data.address,
          payment_city: this.shipping.data.city,
          payment_postcode: this.shipping.data.postcode,
          payment_country: this.shipping.data.country,
          payment_country_id: this.shipping.data.country_id,
          payment_zone: this.shipping.data.zone,
          payment_zone_id: this.shipping.data.zone_id,
          payment_method: this.payment.method,
          payment_code: this.payment.code,

          shipping_firstname: this.shipping.data.firstname,
          shipping_lastname: this.shipping.data.lastname,
          shipping_company: this.shipping.data.firstname,
          shipping_address_1: this.shipping.data.address,
          shipping_city: this.shipping.data.city,
          shipping_postcode: this.shipping.data.postcode,
          shipping_country: this.shipping.data.country,
          shipping_country_id: this.shipping.data.country_id,
          shipping_zone: this.shipping.data.zone,
          shipping_zone_id: this.shipping.data.zone_id,
          shipping_method: this.shipping.method,
          shipping_code: this.shipping.code,

          comment: this.shipping.data.comment,
          total: this.total,
          order_status_id: '1',
          ip: this.customer.ip
        },
        order_product: this.order.list,
        order_total: {
          subtotal: this.subtotal,
          shipping: this.shipping.method,
          value: this.shipping.total,
          total: this.total
        }
      }

      axios.post(settings.api().endpoint + settings.api().path + '/add_order', data, { headers }).then( (r) => {
        this.order_id = r.data.data

        data.order.order_id = this.order_id
        this.$store.commit('set_order_final', data)

        var data_transaction = {
          order_id: this.order_id,
          total: this.total,
          firstname: this.customer.firstname,
          lastname: this.customer.lastname,
          email: this.customer.email,
          phone: this.customer.telephone
        }
        axios.post(settings.api().endpoint + settings.api().path + '/new_transaction', data_transaction, { headers }).then( (r) => {
          this.url = r.data.data

          setInterval(() => {
            var pay_status = this.$cookies.get('pay_status')
            if(pay_status != null) {
              data.order.order_status_id = pay_status
              this.$store.commit('set_order_final', data)
              
              this.$cookies.remove("store_order")
              this.$cookies.remove('pay_status')
              
              this.$router.push('/resume')
            }
          }, 3000);
        })
      })
    }
  },
  mounted() {
    this.url = ''

    this.xwt = this.$store.getters.get_token
    this.order = this.$store.getters.get_order
    this.customer = this.$store.getters.get_customer
    this.shipping = this.$store.getters.get_shipping
    this.payment = this.$store.getters.get_payment

    this.subtotal = this.order.data.total
    this.total = this.subtotal + this.shipping.total
  },
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: 1300px;
}
</style>